import React from "react";
import { resourceCategories } from "../../constants";

import ResourceCenter from "../../page-components/resource-center";

const Page = () => {
  return <ResourceCenter defaultResource={resourceCategories.TOOLS} />;
};

export default Page;
